export const StatusRowHeader = () => {
    return (
        <div className="rowContainer">
            <div className="row">
                <div className="rowId" />
                <div className="rowName" style={{ fontWeight: "600" }}>
                    Service
                </div>
            </div>
            <div className="row rowDomain" style={{ justifyContent: "space-between" }}>
                <div className="rowUrl" style={{ fontWeight: "600" }}>
                    Domain
                </div>
                <div className="rowUrl">
                    
                </div>
            </div>
        </div>
    )
}
