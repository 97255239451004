import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from "react-helmet";
import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Stellar Shroomz - Status</title>
          <meta name="description" content="Status page for Stellar Shroomz services." />
          <link rel="canonical" href="https://shroomztoken.xyz/status" />
        </Helmet>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
