import { z } from "zod";
import { Site } from "./App";

export enum NodeEnv {
  PROD = "production",
  DEV = "development",
  TEST = "test",
}

const urls = [
  {
    name: "Website",
    url: "https://shroomztoken.xyz",
  }
]

const urlObject = z.object({
  name: z.string(),
  url: z.string(),
})

const configSchema = z.object({
  NODE_ENV: z.nativeEnum(NodeEnv).default(NodeEnv.PROD),
  URLS: z.array(urlObject).optional(),
});

export const appConfig = configSchema.parse({
  URLS: urls,
  NODE_ENV: process.env.NODE_ENV
});

export interface ConfigProps {
  URLS: Site[];
  NODE_ENV: NodeEnv
}

export const config: ConfigProps = {
    URLS: urls,
    NODE_ENV: process.env.NODE_ENV as NodeEnv
}
