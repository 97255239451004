import "./StatusRow.css";
import { IconOk } from "./components/IconOk";
import { IconError } from "./components/IconError";
import { Site } from "src/App";

export const StatusRow = ({
    name,
    url,
    active
}: Site) => {
    return (
        <div className="rowContainer" style={{ border: 0}}>
            <div className="row">
                <div className="rowId" />
                <div className="rowName">
                    {name}
                </div>
            </div>
            <div className="row" style={{ justifyContent: "space-between" }}>
                <div className="rowUrl rowDomain">
                    <a href={url} target="_blank" rel="noreferrer">{url}</a>
                </div>
                <div
                    className="rowUrl" 
                    style={{ 
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        fontWeight: "600"
                    }}
                >
                    <p className="rowStatusText">{active ? "RUNNING" : "DOWN"}</p>
                    {active ? <IconOk size="16" color="#091220" /> : <IconError size="16" color="#091220" />}
                </div>
            </div>
        </div>
    )
}
