import "./Section.css";

interface ButtonProps {
    children?: any;
    style?: any;
    className?: string;
}

export const Section = ({
    children,
    style,
    className
}: ButtonProps) => {
    return (
        <div className={`sectionContainer ${className}`} style={style}>
            {children}
        </div>
    )
}
