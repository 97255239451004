import './App.css';
import { useEffect, useState } from 'react';
import { StatusRow } from './components';
import { StatusRowHeader } from './components/RowHeader';
import { Section } from './components/Section';
import { Spacer } from './components/Spacer';
import { Title } from './components/Title';
import { config } from './config';
import axios from 'axios';

export interface Site {
  name: string;
  url: string;
  active?: boolean;
}

function App() {
  const [list, setList] = useState<Site[] | null>(null);

  const fetchData = async () => {
    const { URLS } = config;

    for (let i = 0; i < URLS.length; i++) {
      try {
        const response = await axios.get(URLS[i].url);
        if (response.status === 200) {
          URLS[i].active = true;
        } else {
          URLS[i].active = false;
        }
      } catch (error) {
        URLS[i].active = false;
      }
    }
    setList(URLS);
  }

  useEffect(() => { fetchData() }, [])

  return (
      <Section className="sectionContainerLower" style={{ overflow: "scroll" }}>
        <Title title="STATUS - SHROOMZ" />
        <Spacer height={"2rem"} />
        <div className="statusContainer">
          <StatusRowHeader />
          <Spacer height={"1rem"} />
          {list && list.map((item: any, index: number) => (
            <StatusRow key={index} {...item} />
          ))}
        </div>
      </Section>
  );
}

export default App;
